export const LOGIT_INTERCEPTS = {
    1: -0.5603609044313885,
    2: -2.377800728983603,
    3: -3.4279405730644945,
    4: -1.4683014571571005,
    5: -1.8229824872505176,
    6: -2.468209207220081,
    7: -2.3178116860133158,
    8: -2.9560227799834267,
    9: -3.861842673378925,
    10: -3.5043264214366685,
    11: -3.1206479773079465,
    12: -2.3315279075605937,
    13: -1.661503588203104,
    14: -2.5599989854141008,
    15: 0.0,
    16: -1.2455693126477838,
    17: -1.1138568250732555,
    18: -2.238047584671551,
    19: -0.5580890605291967,
    20: -1.5411389978811232,
};
export const LOGIT_PFA = {
    1: 0.14789618886840314,
    2: 0.24854329943826486,
    3: 0.23701309180368918,
    4: 0.18387639466137584,
    5: 0.2631873710660165,
    6: 0.28229261603521594,
    7: 0.2446228716946292,
    8: 0.28976429899938105,
    9: 0.35430589214301667,
    10: 0.2274223932273325,
    11: 0.16244699821793984,
    12: 0.22479691314928582,
    13: 0.2348687281447527,
    14: 0.25080219636677975,
    15: 0.27094548691792963,
    16: 0.18400738686221182,
    17: 0.15808996206682338,
    18: 0.18110886088875539,
    19: 0.2709243135260724,
    20: 0.2716456606554334,
};
export const LOGIT_NFA = {
    1: 0.45575402798773496,
    2: 0.31722793259038556,
    3: 0.2932462377367661,
    4: 0.5262915254055291,
    5: 0.3708053286498755,
    6: 0.3839821803861652,
    7: 0.29607955629653737,
    8: 0.3012950000748625,
    9: 0.25471640881157553,
    10: 0.3444300677476307,
    11: 0.42319250872325836,
    12: 0.4656464483687326,
    13: 0.4788725331606935,
    14: 0.3498333996655358,
    15: 0.49679065264794114,
    16: 0.4287603289261475,
    17: 0.46834589220387396,
    18: 0.46196235237383876,
    19: 0.424842031735631,
    20: 0.37769384696515856,
};
export const LOGIT_IS_POS2 = {
    1: 0.030199709335537412,
    2: 0.0805861616272433,
    3: 0.22085506898787546,
    4: 0.2965728036447961,
    5: 0.21690585846683016,
    6: 0.22468319026177844,
    7: 0.36208703159471667,
    8: -0.007243001612022369,
    9: 0.1717524071852162,
    10: 0.40613046470384323,
    11: 0.6128878632986084,
    12: 0.3330789955355113,
    13: 0.3395011949319553,
    14: 0.20945179320894397,
    15: 0.1505552751570884,
    16: 0.04264169585831198,
    17: 0.1110335974928474,
    18: 0.37493949694346135,
    19: 0.19026829776009832,
    20: 0.07049611693526214,
};
export const LOGIT_IS_POS3 = {
    1: 0.31716165864097967,
    2: 0.45798649908358835,
    3: 0.5839297460753263,
    4: 0.5753493354484415,
    5: 0.506612433260569,
    6: 0.3642715051315994,
    7: 0.6064352822610817,
    8: 0.24551233825699453,
    9: 0.5111359121541746,
    10: 0.8194237632429673,
    11: 0.6275395780040631,
    12: 0.5589061923337045,
    13: 0.5516036531103772,
    14: 0.45083522168823714,
    15: 0.40270073945914886,
    16: 0.33486893969967185,
    17: 0.24801300153736938,
    18: 0.6954704833334973,
    19: 0.5172498092817297,
    20: 0.5239647285824043,
};
export const LOGIT_IS_POS4 = {
    1: 0.5039690746143107,
    2: 0.6940327695606686,
    3: 0.789936826500002,
    4: 0.8668609588029782,
    5: 0.7542700495466939,
    6: 0.6881608630593871,
    7: 0.8423203494075644,
    8: 0.6411785172775369,
    9: 0.997462931850135,
    10: 0.9836563224578644,
    11: 1.112427914612344,
    12: 0.9884502013551144,
    13: 0.9180973869146446,
    14: 0.7142991762875909,
    15: 0.5502068956721405,
    16: 0.6820924883748011,
    17: 0.544972649035165,
    18: 0.9702051957099042,
    19: 0.7355348349564758,
    20: 0.7916433390205275,
};
